@font-face {
  font-family: 'agency-fb';
  src: url("/src/assets/font/agencyfb_reg.ttf") format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'agency-fb';
  src: url("/src/assets/font/agencyfb_bold.ttf") format('truetype');
  font-weight: bold;
  font-style: normal;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'agency-fb', sans-serif;
  }

  .font-outline-4 {
    -webkit-text-stroke: 1px black;
  }
}

@layer utilities {
  .scrollbar-hidden {
    scrollbar-width: none;
  }

  .scrollbar-hidden::-webkit-scrollbar {
    display: none;
  }

  .shadow-all {
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
  }

  .shadow-all-yellow {
    box-shadow: rgba(246, 255, 0, 0.3) 0px 1px 8px;
  }

  @media print {
    .print-fill {
      -webkit-print-color-adjust: exact;
    }

    .print-no-print {
      display: none;
    }

    .print-pagebreak {
      page-break-before: always;
    }

    .break-inside-avoid {
      break-inside: avoid;
    }

    .print-no-padding {
      padding: 0,
    }

    .print-no-margin {
      margin: 0,
    }

    .print-no-border {
      border: 0,
    }
  }
}